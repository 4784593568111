import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTextareaAutosize from 'vue-textarea-autosize'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

Vue.use(VueTextareaAutosize)
Vue.config.productionTip = false

firebase.initializeApp({
  apiKey: "AIzaSyDHlezvhVGUlXNArdyRiI2yYejNV-_vR9g",
  authDomain: "vue-calendar-e0d86.firebaseapp.com",
  projectId: "vue-calendar-e0d86",
  storageBucket: "vue-calendar-e0d86.appspot.com",
  messagingSenderId: "717839235080",
  appId: "1:717839235080:web:38ef7f6032e3a2213c39bd"
})

export const db = firebase.firestore();

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
