<template>

  <div>
    <div style="width: 100%; height: 30vh; position: absolute; display: flex;
    flex-direction: column;
    justify-content: center; background-image: linear-gradient(0deg, #7c7c7c, rgb(255 255 255 / 30%) 20%);">
      <div>
      </div>
    </div>

    <div style="width: 100%; height: 30vh; overflow: hidden;">
      <img src="backgroundIMG.jpg" alt="this slowpoke moves" style="width: 100%; height: 100%; object-fit: cover;" />
    </div>
 <div style="background: #7c7c7c;">
  <div style="text-align: -webkit-center;">
    <div class="fonttypeSub">
      The Humarock House Website
    </div>
    <div class="fonttypeMain">
      Supporting The Preskenis Family
    </div>
 <p class="humarock-intro"> Humarock (often called Humarock Beach or Humarock Island) is part of Scituate, Massachusetts, United States. Humarock is a picturesque seaside village surrounded by water and situated on Cape Cod Bay midway between Boston and Plymouth. It was separated from the rest of the town in the Portland Gale of 1898 in which the mouth of the North River shifted. Humarock is now accessible from Scituate only by boat or from the Town of Marshfield by bridge.  Humarock is also home to the Preskenis family.</p>
  </div>
  <div style="text-align: -webkit-center; padding: 16px;">
    <v-divider style="width: 50%; background-color: #ffffff99;"></v-divider>
  </div>
  <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
  <v-card
    class="mx-auto my-12"
    max-width="350"
    flat
  >
    <v-img
      src="fireworks.jpg"
      style="14.381875rem"
    ></v-img>
    <v-card-title class="information-title">Use The Calendar</v-card-title>
    <v-card-text style="display: flex; align-items: center; ">
      <div style="text-align: initial; padding-right: 10px;">
      Use an easy to Hummarock House calendar and fill it with events.  Your events are visiable to everyone!
      </div>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
  <v-card
    class="mx-auto my-12"
    max-width="350"
    flat
  >
    <v-img
      src="backgroundIMG2.jpg"
      style="14.381875rem"
    ></v-img>
    <v-card-title class="information-title">Add Photos</v-card-title>
    <v-card-text style="display: flex; align-items: center; ">
      <div style="text-align: initial; padding-right: 10px;">
      Add photos, browse photos and download photos without having to navigate through old photo albums.
      </div>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
  <v-card
    class="mx-auto my-12"
    max-width="350"
    flat
  >
    <v-img
      src="backgroundIMG3.jpg"
      style="14.381875rem"
    ></v-img>
    <v-card-title class="information-title">Check The Weather</v-card-title>
    <v-card-text style="display: flex; align-items: center; ">
      <div style="text-align: initial; padding-right: 10px;">
      Check the weather of Humarock in real-time.  Can you do this on a phone... sure.  But why not use this fancy website.
      </div>
    </v-card-text>
    <v-card-actions>
    </v-card-actions>
  </v-card>
 </div>
  <div style="text-align: -webkit-center; padding: 16px;">
    <v-divider style="width: 50%; background-color: #ffffff99;"></v-divider>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="fill: white;" version="1.1" id="Your_Icon" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" enable-background="#FFFFFF" xml:space="preserve">
<path d="M89.163,20.139c-14.103,0-33.235,17.606-35.841,28.565c-0.569-1.253-1.355-2.144-2.061-2.629  c1.26-4.288,7.351-9.814,8.349-14.258c0.109-0.484-0.89-0.941-1.015-0.442c-1.316,5.271-6.477,9.938-7.586,14.542  c-0.278-0.157-0.536-0.247-0.755-0.264v-0.003c-0.013,0-0.025,0.001-0.038,0.001c-0.013,0-0.025-0.001-0.038-0.001v0.003  c-0.219,0.017-0.477,0.106-0.755,0.264c-1.11-4.604-6.269-9.271-7.586-14.542c-0.125-0.5-1.123-0.042-1.014,0.442  c0.997,4.444,7.089,9.971,8.348,14.258c-0.705,0.486-1.491,1.376-2.06,2.629C44.506,37.745,25.373,20.139,11.27,20.139  c-4.671,0-6.737,0.809-6.737,3.324c0,7.276,11.537,29.025,15.708,31.349c1.727,0.962,3.896,1.207,5.917,0.817  c-2.485,2.049-4.289,5.194-4.289,9.513c0,10.374,7.205,15.721,14.283,15.721c7.649,0,11.229-6.597,11.229-22.906  c0.808,1.976,1.707,4.041,1.707,7.636c0,6.221-1.319,14.114,1.092,14.278v0.004c0.013,0,0.025-0.002,0.038-0.003  c0.013,0.001,0.025,0.003,0.038,0.003V79.87c2.411-0.164,1.092-8.058,1.092-14.278c0-3.595,0.897-5.66,1.706-7.636  c0,16.31,3.58,22.906,11.229,22.906c7.078,0,14.283-5.347,14.283-15.721c0-4.318-1.804-7.464-4.288-9.513  c2.02,0.39,4.188,0.145,5.915-0.817C84.363,52.487,95.9,30.738,95.9,23.462C95.9,20.947,93.834,20.139,89.163,20.139z"/>
</svg>
<div style="height: 75px;"></div>

 </div>

  </div>
</template>

<script>


  export default {
    name: 'HelloWorld',

    data: () => ({

          items: [
          {
            src: 'image1.jpg',
          },
          {
            src: 'image2.jpg',
          },
          {
            src: 'image3.jpg',
          },
          {
            src: 'image4.jpg',
          },
        ],
    }),
    methods: {
      signin() {
        console.log("signin")
        this.$emit("signin")
      }
    }
  }
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;400;800&family=Open+Sans&display=swap');
/* Style that first letter! */
.first-letter {
  font-size: 45px;
  line-height: 80px;
  color: white;
}
.humarock-intro {
  color: white;
  max-width: 70%;
  font-family: "Karla", sans-serif;
  padding-top: 16px;
}
.humarock-intro::first-letter {
  color: white;
  font-size: 150%;
  font-weight: bold;
}

.mobile-carosel{
  width: 50%;
  height: vh;
    @media (max-width: 560px) {
    width: 100%;
    height: 30%;
  }
}
.fonttypeMain {
    font-family: "Karla", sans-serif;
    color: white;
    font-size: 42px;
    font-weight: bolder;
    max-width: 70vw;
    display: block;
    margin: 0 -0.5rem -0.25rem;
    font-size: 3.25rem;
    font-weight: 100;
    line-height: 3.375rem;
    letter-spacing: 0;
}
.fonttypeSub {
    font-family: "Karla", sans-serif;
    color: white;
    font-weight: bolder;
    font-size: 16px;
    font-size: .9375rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    padding: 16px;
}
.information-title {
  align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all;
    font-size: .9875rem;
    line-height: 1.5rem;
    color: #0b1f66;
    text-transform: uppercase;
    font-weight: bold;
}

</style>
